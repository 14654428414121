import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";

const Gausevak = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email to the user (confirmation)
    emailjs.send(
      'service_edh7kpb', // Your EmailJS service ID
      'template_u2vycla', {
      user_name: formData.name,
      user_email: formData.email,
      user_phone: formData.phone,
      user_address: formData.address,
      message: formData.message,
    }, // Your EmailJS template ID for user confirmation

      'qEe6PoknuLKZeXcEI' // Your EmailJS public key
    )
      .then((response) => {
        console.log('User email sent successfully!', response.status, response.text);
        setFormData({
          name: "",
          email: "",
          phone: "",
          address: "",
          message: "",
        });

      }, (err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-[#f0dcd5] rounded-lg shadow-lg">
      <div className="flex items-center justify-center m-8 p-8 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951]">Apply to Become a Gausevak</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>
      <div className="flex flex-col items-center p-8 bg-[#ffd2b3] rounded-md shadow-md shadow-black w-full">
        <form onSubmit={handleSubmit} className="w-full md:w-3/4 lg:w-1/2">
          <div className="mb-4">
            <label className="block text-gray-700">Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">
              Message (Why do you want to join?)
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-red-900 hover:bg-red-700 text-white font-bold py-2 rounded"
          >
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
};

export default Gausevak;
