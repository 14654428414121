import farm1 from "../assests/headerImage/farm1-removebg.png";
import fullLogo from "../assests/headerImage/fulllogo_transparent.png";
import slogan from "../assests/headerImage/slogan-san111.png";
function SubHeader() {
  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <img
            src={fullLogo}
            alt="fulllogo"
            className="h-24 w-32 sm:h-32 sm:w-40 md:h-36  md:w-48 lg:h-44 lg:w-56 xl:h-48 xl:w-64"
          />
        </div>

        <div className="">
          <img
            src={slogan}
            alt="slogan"
            className="bg-transparent rounded-lg h-20 w-40 sm:h-28 sm:w-48 md:h-32 md:w-56 lg:h-36 lg:w-64 xl:h-40 xl:w-72"
          />
        </div>

        <div>
          <img
            src={farm1}
            alt="farm"
            className="h-24 w-32 sm:h-32 sm:w-40 md:h-36 md:w-48 lg:h-44 lg:w-56 xl:h-48 xl:w-64"
          />
        </div>
      </div>
    </>
  );
}

export default SubHeader;
