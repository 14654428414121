import React from "react";
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import SubHeader from "../components/SubHeader";
import treatment from "../assests/OurGaushakaImage/9.jpg"
import shelter from "../assests/OurGaushakaImage/Shelter.jpg"
import breeding from "../assests/OurGaushakaImage/breeding.jpg"
import milk from "../assests/OurGaushakaImage/milk.jpeg"
import fodder from "../assests/OurGaushakaImage/foods.png"

const OurWorks = ({ showSubHeader = true }) => {
  const { t } = useTranslation(); // Initializing the translation hook

  // Define an array of objects for the card data
  const cards = [
    {
      imgSrc:shelter,
      // imgSrc:
      //   "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215659/test_Api/mpbyoc0h97njq6ga2sjp.jpg",
      subtitleKey: "shelter.subtitle", // Changed to use translation key
      textKey: "shelter.text", // Changed to use translation key
    },
    {
      imgSrc: treatment,
      subtitleKey: "treatment.subtitle", // Changed to use translation key
      textKey: "treatment.text", // Changed to use translation key
    },
    {
      imgSrc:milk,
      // imgSrc:
      //   "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215845/test_Api/nh2b4c8lb8wczoqjcn6g.jpg",
      subtitleKey: "freeMilkDistribution.subtitle", // Changed to use translation key
      textKey: "freeMilkDistribution.text", // Changed to use translation key
    },
    // {
    //   imgSrc:
    //     "https://media.assettype.com/TNIE%2Fimport%2F2019%2F3%2F13%2Foriginal%2FCow.jpg?w=480&auto=format%2Ccompress&fit=max",
    //   subtitleKey: "protectionDrives.subtitle", // Changed to use translation key
    //   textKey: "protectionDrives.text", // Changed to use translation key
    // },
    // {
    //   imgSrc:
    //     "https://anudinamgoshala.org/wp-content/uploads/2022/05/ARS_0743_1-min-scaled.jpg",
    //   subtitleKey: "protectionToCows.subtitle", // Changed to use translation key
    //   textKey: "protectionToCows.text", // Changed to use translation key
    // },
    {
      imgSrc:breeding,
      // imgSrc:
      //   "https://media.istockphoto.com/id/1220128734/photo/cow-feeding-milk-to-newborn-calf.jpg?s=612x612&w=0&k=20&c=gz3yC_v5F1MCLDaUS3uJBoQvwJfcpIxMTXlvOtSxTOk=",
      subtitleKey: "breeding.subtitle", // Changed to use translation key
      textKey: "breeding.text", // Changed to use translation key
    },
    // {
    //   imgSrc:
    //     "https://i.ytimg.com/vi/PF9DgauPZEA/hq720.jpg?sqp=-oaymwE7CK4FEIIDSFryq4qpAy0IARUAAAAAGAElAADIQj0AgKJD8AEB-AH-CYAC0AWKAgwIABABGEUgTShlMA8=&rs=AOn4CLA9HlozufppAev4FTPwsSFEbb6ieg",
    //   subtitleKey: "training.subtitle", // Changed to use translation key
    //   textKey: "training.text", // Changed to use translation key
    // },
    {
      imgSrc:fodder,
      subtitleKey: "cultivationOfGreenFodder.subtitle", // Changed to use translation key
      textKey: "cultivationOfGreenFodder.text", // Changed to use translation key
    },
  ];

  return (
    <div className="m-4 md:m-8">
      {showSubHeader && <SubHeader />}

      <div className="flex items-center justify-center lg:m-8 sm:m-2 sm:p-2 sm:text-sm lg:p-8 space-x-4 text-center">
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon1} alt="icon1" />
        <p className=" sm:text-lg lg:text-2xl font-bold text-[#F17951]">{t('our_work')}</p>
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="flex flex-wrap justify-center gap-4 md:gap-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`card flex flex-col md:flex-row border border-gray-400 rounded-lg bg-[#ffd2b3] w-full max-w-4xl ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
          >
            <div className="w-full md:w-1/2 flex-shrink-0 p-4 flex justify-center">
              <img
                className="h-64 w-80 object-cover rounded-lg  " // Set fixed height and width
                src={card.imgSrc}
                alt={t(card.subtitleKey)} // Fetching translated subtitle
              />
            </div>
            <div className="w-full md:w-1/2 p-4 flex flex-col justify-center">
              <div className="flex items-center justify-center space-x-4">
                
                <p className="text-2xl font-bold text-[#F17951] my-2">
                  {t(card.subtitleKey)} {/* Fetching translated subtitle */}
                </p>
              </div>
              <p className="inner-text flex justify-center items-center ">
                {t(card.textKey)} {/* Fetching translated text */}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurWorks;
