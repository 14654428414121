import React, { createContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]); // Array of objects
  const [isVisible, setIsMenuVisible] = useState(false); // Manage visibility state
  const clearCart = () => {
    setCartItems([]);
    setCartCount(0);
  };

  const addToCart = (item) => {
    setCartItems((prevItems) => {
      // Filter out any items that are not of the current type (ourproduct)
      const filteredItems = prevItems.filter(
        (cartItem) => cartItem.type === item.type
      );

      // Check if the current item already exists in the filtered cart
      const existingItemIndex = filteredItems.findIndex(
        (cartItem) => cartItem.id === item.id
      );

      let updatedCartItems;

      if (existingItemIndex !== -1) {
        // If the item exists, update its quantity
        updatedCartItems = filteredItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        // If the item doesn't exist, add it with quantity 1
        updatedCartItems = [...filteredItems, { ...item, quantity: 1 }];
      }

      // Update the count based on the updated cart items
      setCartCount(
        updatedCartItems.reduce((total, cartItem) => total + cartItem.quantity, 0)
      );

      return updatedCartItems;
    });


  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (cartItem) => cartItem.id === itemId
      );

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        if (updatedItems[existingItemIndex].quantity > 1) {
          // Decrease the quantity if more than 1
          updatedItems[existingItemIndex].quantity -= 1;
          setCartCount((prev) => prev - 1);
        } else {
          // Remove the item if the quantity is 1
          //   const newCartCount = prevItems.length > 1 ? prevItems.length - 1 : 0; // Adjust count

          updatedItems.splice(existingItemIndex, 1);
          setCartCount((prev) => Math.max(prev - 1, 0));
        }
        return updatedItems;

        // Update the cart count
      }

      return prevItems; // If item isn't found, return the array unchanged
    });
  };

  return (
    <CartContext.Provider
      value={{
        cartCount,
        setCartCount,
        addToCart,
        removeFromCart,
        cartItems,
        isVisible,
        setIsMenuVisible,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
