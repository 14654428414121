import React, { useState } from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import SubHeader from "../components/SubHeader";
import axios from 'axios';

function Test() {
  // State to manage form fields
  const [formData, setFormData] = useState({
    currency: "",
    amount: "",
    email: "",
    phone: "",
    fullName: "",
    panNumber: "",
  });

  // Handle state change
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handlePayment = async (event) => {
    event.preventDefault();
  
    const data = {
      Name: formData.fullName,
      Phone: formData.phone,
      Amount: formData.amount,
      Email: formData.email,
      PanNumber: formData.panNumber,
    };
  
    // Create a new FormData instance
    let formDataObj = new FormData();
  
    // Append each key-value pair to the FormData object
    for (const key in data) {
      formDataObj.append(key, data[key]);
    }
  
    try {
      let resData = await fetch('https://script.google.com/a/macros/vallabhiindia.com/s/AKfycbzuXMEcVXhsC9yCMDzEgvQN6lEGhp1c056Jhsw_Mm91LJtvB0MlAtQ5GPmp8mgUg2slbg/exec', {
        method: "POST",
        body: formDataObj, // Pass the FormData object here
      });
  
      // Use resData.text() if the response is plain text, not JSON
      let responseText = await resData.text();
      console.log('API Response:', responseText);
  
      // If you expect a specific text like "Data Saved", handle it accordingly
      if (responseText === "Data Saved") {
        console.log("Data successfully saved!");
      } else {
        console.log("Unexpected response:", responseText);
      }
  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <div className="container mx-auto px-8 py-8">
      <SubHeader />
      <div className="flex items-center justify-center m-8 p-8 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951] ">Shrinarayan Vrihad Gaushala Donation Form</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      {/* Block 1: Choose Currency & Amount */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 mx-auto max-w-custom">
        <h2 className="text-2xl font-bold mb-4 text-[#F17951]">Choose Currency & Amount</h2>
        <div className="flex flex-col md:flex-row md:space-x-4">
          {/* Input Field for Amount */}
          <div className="w-full">
            <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
              Enter Amount
            </label>
            <input
              id="amount"
              name="amount" // Add name attribute
              type="text"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter Amount"
              className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-[#F17951]  focus:border-[#F17951] block w-full p-2.5"
            />
          </div>
        </div>
      </div>

      {/* Block 2: Personal Details */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 mx-auto max-w-custom">
        <h2 className="text-2xl font-bold mb-4 text-[#F17951]">Personal Details</h2>
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          {/* Email and Phone Number */}
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              Email Address
            </label>
            <input
              id="email"
              name="email" // Add name attribute
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-[#F17951]  focus:border-[#F17951] block w-full p-2.5"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
              Phone Number
            </label>
            <input
              id="phone"
              name="phone" // Add name attribute
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-[#F17951]  focus:border-[#F17951]block w-full p-2.5"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          {/* Full Name and PAN Number */}
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900">
              Full Name
            </label>
            <input
              id="fullName"
              name="fullName" // Add name attribute
              type="text"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-[#F17951]  focus:border-[#F17951] block w-full p-2.5"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="panNumber" className="block mb-2 text-sm font-medium text-gray-900">
              PAN Number
            </label>
            <input
              id="panNumber"
              name="panNumber" // Add name attribute
              type="text"
              value={formData.panNumber}
              onChange={handleChange}
              placeholder="Enter your PAN number"
              className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-[#F17951]  focus:border-[#F17951]  block w-full p-2.5"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center px-8 sm:px-6 md:px-8 lg:px-64">
        <button
          type="button"
          onClick={handlePayment}
          className="text-white bg-[#F17951] focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm sm:text-base px-4 sm:px-5 py-2.5 sm:py-3 me-2 mb-2 dark:bg-red-600 hover:bg-[#f38b68] focus:outline-none dark:focus:ring-red-800 w-full max-w-xs"
        >
          Proceed
        </button>
      </div>
    </div>
  );
}

export default Test;
