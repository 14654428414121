import React from "react";
import myImage from "../assests/calf.JPG";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next"; // Import useTranslation

function About() {
  const { t } = useTranslation(); // Initialize translation

  return (
    <div className="m-4 md:m-8">
      <div className="flex items-center justify-center lg:m-8 sm:m-2 sm:p-2 sm:text-sm lg:p-8 space-x-4 text-center">
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-sm sm:text-lg lg:text-2xl font-bold text-[#F17951]">{t('about_us')}</p> {/* Use translation */}
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon2} alt="icon2" />
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-between px-8 py-8 my-4 bg-[#ffd2b3] border border-black rounded-lg">
        {/* Left side - Image */}
        <div className="about-image w-full lg:w-1/2 flex justify-center lg:justify-start">
          <img
            src={myImage}
            alt="About us"
            className="rounded-lg shadow-md w-full max-w-md"
          />
        </div>

        {/* Right side - Text */}
        <div className="heading w-full lg:w-1/2 mt-8 lg:mt-0 lg:ml-8">
          <div className="right-side-down text-gray-600">
            <p className="leading-relaxed">
              {t('description_about')} {/* Use translation */}
            </p>

            <div className="readmore_btn flex items-center justify-start mt-4">
              <Link
                to="/aboutDetail"
                className="text-[#F17951] hover:text-red-500 font-semibold"
              >
                {t('readMore')} {/* Use translation */}
              </Link>
              <FaArrowRightLong className="arrow-button ml-2 text-[#F17951]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
