import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png"; 
import SubHeader from "../components/SubHeader";
function Feedback() {
  return (
    <>
    <SubHeader/>
     
      <div className="flex items-center justify-center m-8 p-8 space-x-4">
      
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl md:text-3xl  font-bold text-[#F17951] ">Submit your feedback here</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>
      <div className="flex justify-center m-8 md:mx-12 lg:mx-64 p-4">
        <div className="flex flex-col gap-8 bg-[#ffd2b3]  p-6 shadow-lg rounded-lg w-full max-w-md">
          <div className="flex flex-col md:flex-row gap-4 md:gap-8">
            <input
              className="border-2 border-[#F17951] rounded-md h-10 px-2 w-full md:w-1/2"
              type="text"
              placeholder="Enter your name"
            />
            <input
              className="border-2 border-[#F17951]  rounded-md h-10 px-2 w-full md:w-1/2"
              type="email"
              placeholder="Email"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              className="border-2 border-[#F17951]  rounded-md w-full h-32 p-2"
              placeholder="Enter your feedback"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              Upload Attachment
            </label>
            <input
              className="border-2 border-[#F17951] h-16 pt-4 ps-4 rounded-md w-full flex items-center justify-center cursor-pointer"
              type="file"
            />
          </div>
          <button className="bg-[#F17951] text-white h-10 rounded-lg w-full mt-4 hover:bg-[#f38b68] focus:outline-none focus:ring-2 focus:ring-red-500">
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default Feedback;
