import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Calendar = () => {
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const changeMonth = (delta) => {
    setCurrentMonth((prevMonth) => {
      const newDate = new Date(prevMonth);
      newDate.setMonth(prevMonth.getMonth() + delta);
      return newDate;
    });
  };

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  const month = currentMonth.getMonth();
  const year = currentMonth.getFullYear();
  const daysInCurrentMonth = daysInMonth(month, year);
  const firstDay = firstDayOfMonth(month, year);
  const days = [...Array(daysInCurrentMonth)].map((_, index) => index + 1);

  const hindiMonths = [
    'जनवरी', 'फ़रवरी', 'मार्च', 'अप्रैल', 'मई', 'जून',
    'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'
  ];

  const getNakshatra = (day) => {
    const nakshatras = ['अश्विनी', 'भरणी', 'कृत्तिका', 'रोहिणी', 'मृगशिरा'];
    return nakshatras[day % nakshatras.length];
  };

  const getPaksha = (day) => (day <= 15 ? 'शुक्ल पक्ष' : 'कृष्ण पक्ष');

  const handleDateClick = (day) => {
    const selectedDate = new Date(year, month, day + 1).toISOString().split('T')[0];
    navigate(`/panchang/${selectedDate}`);
  };

    // Get today's date for comparison
    const today = new Date();
    const isToday = (day) => 
      day === today.getDate() && 
      month === today.getMonth() && 
      year === today.getFullYear();

  return (
    <div className="flex flex-col items-center w-full py-8 mx-4">
      {/* Month navigation */}
      <div className="flex items-center justify-between p-4 rounded-t-lg w-full sm:w-4/5 lg:w-2/3">
        <button
          onClick={() => changeMonth(-1)}
          className="text-white bg-red-900 hover:bg-red-700 rounded-lg text-sm px-4 py-2 transition duration-200"
        >
          Prev Month
        </button>
        <h2 className="text-lg font-semibold">
          {hindiMonths[month]} {year}
        </h2>
        <button
          onClick={() => changeMonth(1)}
          className="text-white bg-red-900 hover:bg-red-700 rounded-lg text-sm px-4 py-2 transition duration-200"
        >
          Next Month
        </button>
      </div>

      {/* Calendar grid */}
      <div className="grid grid-cols-7 gap-1 sm:gap-2 bg-[#ffd2b3] p-2 sm:p-4 shadow-lg shadow-black rounded-lg w-full sm:w-4/5 lg:w-2/3 mx-auto">

        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="font-bold text-center p-1 sm:p-2 text-xs sm:text-base">
            {day}
          </div>
        ))}

        {Array.from({ length: firstDay }).map((_, index) => (
          <div key={index} className="p-1 sm:p-2" />
        ))}

        {days.map((day) => (
          <div
          key={day}
          className={`border border-black p-2 sm:p-4 text-center transform hover:bg-red-300 cursor-pointer text-xs sm:text-base ${
            isToday(day) ? 'bg-red-400 text-white' : ''
          }`} // Highlight current date
          onClick={() => handleDateClick(day)}
        >
            <div>{day}</div>
            {/* <div className="text-sm text-gray-500">{getNakshatra(day)}</div> */}
            <div className="text-sm text-gray-500">{getPaksha(day)}</div>
          </div>
        ))}
      </div>

      {/* Footer space to prevent overlap */}
      <div className="mt-8 mb-16"></div>
    </div>
  );
};

export default Calendar;
