import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { CartContext } from "../components/Context";
import Swal from 'sweetalert2';
import QR from "../assests/QR.jpg"
import axios from 'axios';
import { Link } from "react-router-dom";


const Cart = ({ isVisible, setIsMenuVisible }) => {
  const { cartItems ,clearCart} = useContext(CartContext);
  const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);


  // const handlePayment = async () =>{
  //   const data = {
  //     name: "John Doe",
  //     mobileNumber:1234567890,
  //     amount:totalPrice,
  //     type:"cart"
  //   }
  //   console.log(totalPrice);
    
  //   try {
  //     const response = await axios.post('http://192.168.18.83:8000/create-order', data)
  //     console.log(response.data)
  //     window.location.href = response.data.url
  //   } catch (error) {
  //     console.log("error in payment", error)
  //   }
  // }

  const minimize = () => {
    setIsMenuVisible(false);
  };


  return (
    <div
      className={`fixed top-0 right-0 sm:w-2/3 md:w-1/2 lg:w-1/4 h-full bg-white flex flex-col transition-transform duration-300 z-50 ${
        isVisible ? "translate-x-0" : "translate-x-full"
      }`}
      onClick={minimize} // Close cart on clicking outside
    >
      <div className="flex justify-between items-center bg-[#F17951] p-4 h-28">
        <h2 className="text-xl font-bold text-white">Cart</h2>
        <div className="cursor-pointer text-white text-xl md:text-2xl flex items-center" onClick={minimize}>
          <IoMdClose />
        </div>
      </div>

      <div className="p-4 flex-1 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        {cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cartItems.map(({ id, title, price, quantity, image }) => (
            <div key={id} className="flex items-center justify-between mb-4 border-b pb-2">
              <img src={image} alt={title} className="w-16 h-16 object-cover" />
              <div className="flex-1 ml-4">
                <h2 className="font-semibold">{title}</h2>
                <p>Price: ₹{price*quantity}</p>
                <p>Quantity: {quantity}</p>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="p-4 border-t bg-white sticky bottom-0">
        <div className="flex justify-between text-lg font-semibold">
          <span>Total:</span>
          <span>₹{totalPrice.toFixed(2)}</span>
        </div>
        {/* <div onClick={(e) => e.stopPropagation()}> */}
        <div> 
          {
            totalPrice==0? "Please add some items.":<Link to="/cartform">
            <button 
              // onClick={handlePayment} 
  
              className="w-full bg-[#F17951] text-white py-3 mt-4 font-bold rounded-lg">
              Buy Now
            </button>
            </Link>
          }
          
          
        </div>
      </div>
    </div>
  );
};

export default Cart;
