import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // Use this if you're using React Router

function PaymentFailure() {
    const navigate = useNavigate(); // Hook for navigating to other pages

    useEffect(() => {
        // Trigger the SweetAlert2 on component mount
        Swal.fire({
            icon: 'error',
            title: 'Payment Failed!',
            text: 'Something went wrong with your payment. Please try again.',
            confirmButtonText: 'Close',
            allowOutsideClick: false, // Prevent closing the alert by clicking outside
        }).then(() => {
            // Navigate to the home page after the alert is closed
            navigate('/');
        });
    }, [navigate]);

    return null; // No UI because we are showing an alert and navigating away
}

export default PaymentFailure;
