import React, { useContext } from "react";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { CartContext } from "../components/Context";
import generalMedicine from "../assests/medicine/general.jpg"
import MedicalTreatment from "../assests/medicine/MedicalTreatment.jpg"
import cowOperations from "../assests/medicine/cowOperations.jpg"
import tonic from "../assests/medicine/tonic.jpeg"
import vacination from "../assests/medicine/vacination.jpg"
import SubHeader from "../components/SubHeader";

function Medicine() {
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  const items = [
    {
      id: 7,
      type: "donate_product", title: "General Medicines",
      image: generalMedicine,
      price: 500,
      unit: "",
      text: "Monthly medicine is essential for addressing daily health issues, ensuring our Gauvansh remains safe and healthy",
    },
    {
      id: 8,
      type: "donate_product", title: "MMedical Treatment Of Injured Cows",
      image: MedicalTreatment,
      price: 1500,
      unit: "per cow",
      text: "Injured cows sent to our gaushala receive in-house treatment by doctors, ensuring they are fully cared for until recovery.",
    },
    {
      id: 9,
      type: "donate_product", title: "Operation Of Mother Cows",
      image: cowOperations,
      price: 7500,
      unit: "per cow for complete treatment",
      text: "Gauvansh may require surgeries for medical emergencies, helping to save the precious lives of mother cows.",
    },
    {
      id: 10,
      type: "donate_product", title: "Important Vaccinations",
      image: vacination,
      price: 700,
      unit: "",
      text: "Regular vaccinations and treatments for diseases like Foot and Mouth Disease ensure the safety and health of our gauvansh.",
    },
    {
      id: 11,
      type: "donate_product", title: "Health Tonic",
      image: tonic,
      price: 1300,
      unit: "per bottle",
      text: "Packed with essential nutrients, this powerful tonic boosts immunity and promotes overall health and vitality.",
    },
  ];

  return (
    <>
      <SubHeader />
      <div className="container mx-auto p-6 bg-[#ffd2b3] rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-6 text-[#F17951]">
          Medicines
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {items.map((item) => (
            <div
              key={item.id}
              className="border rounded-lg p-4 text-center shadow-md shadow-black flex flex-col justify-between"
            >
              <div>
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-60 object-cover mb-2 rounded"
                />
              </div>

              <div>
                <h3 className="font-semibold text-lg">{item.title}</h3>
                <p >{item.text}</p>
              </div>

              <div className="flex justify-between items-center">

                <div className="flex justify-between my-4">
                  <div>
                    <h1 className="text-lg font-bold">{item.price}/- </h1>
                    <h3>{item.unit}</h3>
                  </div>
                </div>

                <div className="bg-[#F17951] flex justify-between items-center rounded-lg">
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="px-4 py-3"
                  >
                    <FiMinus className="border-2 border-black" />
                  </button>
                  <p>
                    {cartItems.find((cartItem) => cartItem.id === item.id)
                      ?.quantity || 0}
                  </p>
                  <button onClick={() => addToCart(item)} className="px-4">
                    <IoMdAdd className="border-2 border-black" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>

  );
}

export default Medicine;
