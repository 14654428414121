import React, { useState } from 'react';
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import emailjs from 'emailjs-com';

const VisitGaushalaForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    message: '',
    visitDate: '',
    visitTime: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use EmailJS to send the email
    emailjs.send('service_edh7kpb', 'template_joc38rt', {
      user_name: formData.name,
      user_email: formData.email,
      user_Phone: formData.phone,
      user_address: formData.address,
      visitDate: formData.visitDate,
      visitTime: formData.visitTime,
      message: formData.message,
    }, 'qEe6PoknuLKZeXcEI')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // Optionally reset the form or show a success message
        setFormData({
          name: '',
          email: '',
          phone: '',
          address: '',
          message: '',
          visitDate: '',
          visitTime: '',
        });
      }, (err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-[#f0dcd5] rounded-lg shadow-lg">
      <div className="flex items-center justify-center m-8 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951]">Visit Our Gaushala</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="flex flex-col items-center p-8 bg-[#ffd2b3] rounded-md shadow-md shadow-black w-full">
        <form onSubmit={handleSubmit} className="w-full md:w-3/4 lg:w-1/2">
          {/* Form fields go here */}
          {/* Same fields as before, no changes needed */}
          <div className="mb-4">
            <label className="block text-gray-700">Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Visit Date</label>
            <input
              type="date"
              name="visitDate"
              value={formData.visitDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Visit Time</label>
            <input
              type="time"
              name="visitTime"
              value={formData.visitTime}
              onChange={handleChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Message (Any special requests?)</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-red-900 hover:bg-red-700 text-white font-bold py-2 rounded"
          >
            Submit Visit Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default VisitGaushalaForm;
