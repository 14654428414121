import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai"; // Import close icon from react-icons

const ShlokaPopup = () => {
  const [shloka, setShloka] = useState("");
  const [showPopup, setShowPopup] = useState(false); // Initially, hide the popup

  useEffect(() => {
    // Check if we're on the home page and if the shloka has been shown before
    const isHomePage = window.location.pathname === "/";
    const shlokaShown = localStorage.getItem("shlokaShown");

    if (isHomePage && !shlokaShown) {
      const fetchShloka = async () => {
        try {
          const response = await axios.get(
            "https://shloka.onrender.com/api/v1/sanskrit/slogan/random"
          );
          if (response.data?.Sloka) {
            setShloka(response.data.Sloka);
            setShowPopup(true);
            localStorage.setItem("shlokaShown", "true"); 
          }
        } catch (error) {
          console.error("API Error:", error);
          // Do not show popup if an error occurs
        }
      };

      fetchShloka();
    }
  }, []);

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && shloka && ( // Only show popup if shloka is available
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-96 h-40">
            <h2 className="text-xl font-bold mb-4">श्लोक</h2>
            <p className="mb-4">{shloka}</p>
            {/* Close icon instead of button */}
            <AiOutlineClose
              className="absolute top-2 right-2 text-gray-600 cursor-pointer"
              size={24}
              onClick={closePopup}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShlokaPopup;
