import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import Qr from "../assests/QR.jpg";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';


const Footer = () => {
  return (
    <div id="contact" className="bg-gray-800 text-white py-8 px-4 md:px-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Address Section */}
        <div className="flex flex-col gap-4">
          <p className="flex items-center gap-2">
            <FaLocationDot color="white" size={24} />
            Village: Gairwah, Post: Arshiya, Block: Suithakala, Tehsil:Shahganj,
            Dist: Jaunpur, Uttar Pradesh
          </p>
          <p className="flex items-center gap-2">
            <FaLocationDot color="white" size={24} />
            Village: Mainuddinpur, Post: Mitawan, Block: Tezi Bazar,
            Tehsil:Badlapur, Dist: Jaunpur, Uttar Pradesh
          </p>
          <a
            href="https://wa.me/918595952508"
            className="flex items-center gap-2"
          >
            <FaWhatsapp color="green" size={24} /> +91 8595952508
          </a>
          <a
            href="mailto:vgf@vallabhiindia.com"
            className="flex items-center gap-2"
          >
            <CiMail color="red" size={24} /> <span>vgf@vallabhiindia.com</span>{" "}
          </a>
        </div>

        {/* Links Section */}
        <div className="flex flex-col gap-4 items-center text-center ">
          <Link to="/privacy" className="text-white hover:underline">
            Privacy Policy
          </Link>
          <Link to="/disclaimer" className="text-white hover:underline">
            Disclaimer
          </Link>
          <Link to="/refund" className="text-white hover:underline">
            Refund Policy
          </Link>
          <Link to="/terms" className="text-white hover:underline">
            Terms and Condition
          </Link>
          {/* <div className="flex items-center gap-4">
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} color="#4267B2" />
      </a>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={30} color="#1DA1F2" />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} color="#E1306C" />
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={30} color="#0077B5" />
      </a>
    </div> */}
        </div>

        {/* QR Code Section */}
        <div className="flex flex-col items-center ">
          <div className="flex flex-col items-center ">
            <img
              src={Qr}
              alt="Donate QR Code"
              className="w-40 h-40 md:w-48 md:h-48 mb-4"
            />
            <p className="text-white text-center md:text-right">Vallabhi Green Foundation</p>
            <p className="text-white text-center md:text-right">Donate Here</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
