import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import desiCow from "../assests/desiCow1.jpg";
import fCow from "../assests/fcows.jpg";
import cowMilk from "../assests/cowMilk.jpg";
import cowDug from "../assests/cowDug.jpg";
import cowProduct from "../assests/cowProduct.jpg";
import cowFertilizer from "../assests/cowFertilizer.jpg";
import cow_Environment from "../assests/cow_Environment.avif";
import SubHeader from "../components/SubHeader";

const dataDifferences = [
  {
    title: "Outer Look and Sound",
    desi: "It has a hump on its back. Its neck is hanging & feathery. It only says OM and Amba, which are names of the God.",
    foreign: "It has a flat back without hump and neither it is hanging & feathery. It has a bitter sound. Looks like a cow but is not a cow."
  },
  {
    title: "Milk",
    desi: "Its milk is healthy and is categorized under A2.",
    foreign: "Its milk comes under the category A1, which is very unhealthy and poisonous and leads to thousands of diseases like cancer, etc."
  },
  {
    title: "Gobar & Gaumutra",
    desi: "Gobar & Gaumutra has a variety of uses and exceptional medical properties.",
    foreign: "Gobar & Gaumutra is like the excreta of other animals. It is poisonous."
  },
  {
    title: "Emotions",
    desi: "If the calf of the native Indian cow dies, it stops giving milk and also doesn’t eat or drink. It is full of emotions. So whoever drinks her milk will be loyal, dutiful, and respectful towards their elders, parents, and teachers.",
    foreign: "Even if the calf of the foreign cow dies, it keeps on giving milk and is unaffected by it. It is emotionless and has hardly any feelings."
  }
];

const dataBenefits = [
  {
    title: "Milk",
    image: cowMilk,
    description: "Cow milk is a complete food for all. It reduces acidity, increases immunity, and sharpens the brain. Cow milk is of A2 type and is a main ingredient for many ayurvedic medicines. It helps in fighting diabetes."
  },
  {
    title: "Cow Dung",
    image: cowDug,
    description: "Our Scriptures say that Maa Lakshmi—Goddess of Wealth & Prosperity—dwells in Cow Dung. Cow dung increases fertility and productivity of soil."
  },
  {
    title: "Panchagavya",
    image: cowProduct,
    description: "It is made from Milk, curd, Ghee, gomutra, and Cow dung. These ingredients when mixed in a proper ratio become Panchgavya which has great medical benefits."
  },
  {
    title: "Farming",
    image: cowFertilizer,
    description: "Organic fertilizers made from Gobar and Gaumutra are very helpful to farmers. In today’s world, we are using chemical fertilizers which are a source of many diseases."
  },
  {
    title: "Benefits in the Heavenly World",
    image: cow_Environment,
    description: "Cow is the only animal in the world which breathes in oxygen and exhales out oxygen. Cow dung and gaumutra purify the soil and thus completely balance the environment."
  }
];

function BenefitsOfCow() {
  return (
    <>
      <SubHeader />
      <div className="flex flex-col items-center justify-center m-4 p-4 space-y-4 md:m-8 md:p-8">
        <div className="flex items-center justify-center space-x-4  hidden md:block">
          {/* <img className="h-8 w-auto" src={icon1} alt="icon1" /> */}
          <p className="text-xl md:text-2xl font-bold text-[#F17951] text-center">
            Difference between Desi & Exotic Foreign Breed Cows
          </p>
          {/* <img className="h-8 w-auto" src={icon2} alt="icon2" /> */}
        </div>
      </div>

      <div className="mx-4 md:mx-16 lg:mx-24">
        <div className="mb-8 bg-[#ffd2b3] rounded-lg p-4  hidden md:block">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="text-center flex justify-center items-center">
              <h2 className="font-semibold">Differences</h2>
            </div>
            <div className="text-center items-center">
              <img className="h-48 w-48 md:h-56 md:w-56 object-cover rounded-lg shadow-lg" src={desiCow} alt="Desi Cow" />
              {/* <p className="mt-2">Desi Cow</p> */}
            </div>
            <div className="text-center">
              <img className="h-48 w-48 md:h-56 md:w-56 object-cover rounded-lg shadow-lg" src={fCow} alt="Foreign Cow" />
              {/* <p className="mt-2">Foreign Cow</p> */}
            </div>

          </div>

          <div className="grid grid-cols-1 gap-4 mt-4">
            {dataDifferences.map((item, index) => (
              <div key={index} className="grid grid-cols-3 p-2 border-b border-gray-300">
                <div className="text-center">{item.title}</div>
                <div>{item.desi}</div>
                <div>{item.foreign}</div>
              </div>
            ))}
          </div>
        </div>

        <h1 className="text-center text-lg md:text-xl font-semibold">
          One of the names of Lord Krishna is Gopal (One who takes care of cows) and Govind. He has shown us that by serving Gaumata, we can achieve health, wealth & prosperity along with eternal bliss. A Few Benefits of Desi Cow:
        </h1>

        <div className="my-8 grid gap-8 grid md:grid-cols-2 lg:grid-cols-3">
          {dataBenefits.map((benefit, index) => (
            <div key={index} className=" gap-4 items-center shadow shadow-black  p-2">
              <div className="flex flex-col items-center">
                <img className="h-72 w-72 md:h-80 md:w-80 object-cover rounded-lg shadow-lg" src={benefit.image} alt={benefit.title} />
                <div className="flex items-center justify-center p-4 space-x-4 mt-2">
                  <p className="text-xl md:text-2xl font-bold text-[#F17951]">{benefit.title}</p>
                </div>
              </div>
              <div>{benefit.description}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default BenefitsOfCow;
