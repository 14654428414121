import React from "react";
import ReactDOM from "react-dom/client";
import "./App.scss";
import "./index.css";
import App from "./App";
import "./i18n"; // Import the i18n configuration
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // i18n setup import
import { CartProvider } from "./components/Context"; // Import the CartProvider

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <CartProvider>
        <App />
      </CartProvider>
    </I18nextProvider>
  </React.StrictMode>
);
