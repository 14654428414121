import React, { useState } from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";

const Gallery = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [activeMedia, setActiveMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null); // To differentiate between image and video
  const [showMore, setShowMore] = useState(false); // State to control "View More"

  // Image sources
  const images = [
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728558878964_1.jpg",
    },
    {
      src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217258/test_Api/oakrdpp5r6iacptcqtcb.jpg",
    },
    {
      src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724244521/test_Api/fvm7qbewtpplyv01lzbe.jpg",
    },
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728559365353_2.jpg",
    },
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728643968073_IMG-20240720-WA0008.jpg",
    },
    {
      src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217701/test_Api/j3uive0x9nygejoaj3vq.jpg",
    },
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728643887210_IMG-20240720-WA0016.jpg",
    },
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728644076309_IMG-20240720-WA0012.jpg"
    },
    {
      src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724244634/test_Api/pa5wcm1wellobvnipw3y.webp",
    },
    {
      src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728643726043_IMG-20240720-WA0018.jpg",
    },
  ];

  // Video sources
  const videos = [
    { id: 1, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728639968259_video1.mp4" },
    { id: 2, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640059473_video2.mp4" },
    { id: 3, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640095191_video3.mp4" },
    { id: 4, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640119444_video4.mp4" },
    { id: 5, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640145079_video5.mp4" },
    { id: 6, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640179995_video6.mp4" },
    { id: 7, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640208075_video7.mp4" },
    { id: 8, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640230268_video8.mp4" },
    { id: 9, src: "https://imagesvideoapp.s3.ap-southeast-1.amazonaws.com/1728640256473_video9.mp4" },
  ];

  const handleMediaClick = (src, type) => {
    setActiveMedia(src);
    setMediaType(type);
    setIsZoomed(true);
  };

  const handleCloseZoom = () => {
    setIsZoomed(false);
    setActiveMedia(null);
  };
  return (
    <div>

      <div className="flex items-center justify-center lg:m-8 sm:m-2 sm:p-2 sm:text-sm lg:p-8 space-x-4 text-center">
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-sm sm:text-lg lg:text-2xl font-bold text-[#F17951]">
          Our Gallery</p>
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      {/* Show only 3 images and 3 videos if showMore is false */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mx-8  bg-[#ffd2b3] border border-black rounded-lg">
        {(showMore ? images : images.slice(0, 3)).map((image, index) => (
          <div className="h-auto max-w-full rounded-lg my-8 p-4" key={index}>
            <img
              className="w-full h-48 object-cover rounded-lg cursor-pointer"
              src={image.src}
              alt="gallery"
              onClick={() => handleMediaClick(image.src, "image")}
            />
          </div>
        ))}
        {(showMore ? videos : videos.slice(0, 3)).map((video, index) => (
          <div className="h-auto max-w-full rounded-lg my-8 p-4" key={index}>
            <video
              className="w-full h-48 object-cover rounded-lg cursor-pointer"
              src={video.src}
              controls
              autoPlay
              muted
              loop
              onClick={() => handleMediaClick(video.src, "video")}
            />
          </div>
        ))}
      </div>
      {
        <div className="flex justify-center my-4">
          <button
            onClick={() => setShowMore(!showMore)} // Toggle showMore state
            className="bg-[#F17951] text-white py-2 px-6 rounded-lg text-lg"
          >
            {showMore ? "View Less" : "View More"} {/* Change button text based on state */}
          </button>
        </div>
      }

      {isZoomed && (
        <div className="zoomed-image-overlay" onClick={handleCloseZoom}>
          {mediaType === "image" ? (
            <img src={activeMedia} alt="Zoomed" className="zoomed-image" />
          ) : (
            <video src={activeMedia} controls className="zoomed-image" />
          )}
        </div>
      )}
    </div>
  );
};

export default Gallery;
